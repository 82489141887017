import * as Routes from 'routes/Api';
import FetchHelpers from 'utils/FetchHelpers/JSONFetchHelpers';

export default {
  index(query) {
    const url = Routes.apiV1AdminTagsPath(query);
    return FetchHelpers.get(url);
  },

  nestedOptions(query) {
    const url = Routes.nestedOptionsApiV1AdminTagsPath(query);
    return FetchHelpers.get(url);
  },

  create(tag) {
    const url = Routes.apiV1AdminTagsPath();
    return FetchHelpers.post(url, { tag });
  },

  show(id) {
    const url = Routes.apiV1AdminTagPath(id);
    return FetchHelpers.get(url);
  },

  update(id, tag) {
    const url = Routes.apiV1AdminTagPath(id);
    return FetchHelpers.put(url, { tag });
  },

  bulkDestroy(query) {
    const url = Routes.bulkDestroyApiV1AdminTagsPath(query);
    return FetchHelpers.delete(url);
  },

  bulkPopulate() {
    const url = Routes.bulkPopulateApiV1AdminTagsPath();
    return FetchHelpers.post(url);
  },
};
