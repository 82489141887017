/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { findIndex, reject, update, propEq } from 'ramda';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import OrganizationsRepository from 'repositories/admin/OrganizationsRepository';
import OrganizationDraftRepository from 'repositories/admin/Organization/DraftRepository';
import JobFunctionsRepository from 'repositories/admin/JobFunctionsRepository';
import TopicsRepository from 'repositories/admin/Organization/TopicsRepository';
import LogoRepository from 'repositories/admin/Organization/LogoRepository';
import IndustriesRepository from 'repositories/admin/IndustriesRepository';
import NaicsIndustryCodesRepository from 'repositories/admin/NaicsIndustryCodesRepository';
import CountriesRepository from 'repositories/admin/CountriesRepository';
import StatesRepository from 'repositories/admin/StatesRepository';
import TagRepository from 'repositories/admin/TagRepository';
import AliasesRepository from 'repositories/admin/Organization/AliasesRepository';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

const initialState = {
  jobFunctions: [],
  loading: false,
  organization: null,
  publishedOrganization: null,
  isThomsonReutersAvailable: false,
  saving: false,
};

const adminOrganization = createSlice({
  name: 'adminOrganization',
  initialState,
  reducers: {
    loadOrganizationStart(state) {
      state.loading = true;
    },
    loadOrganizationSuccess(state, { payload }) {
      const { organization, publishedOrganization, isThomsonReutersAvailable } = payload;

      state.organization = organization;
      state.publishedOrganization = publishedOrganization;
      state.isThomsonReutersAvailable = isThomsonReutersAvailable;
      state.loading = false;
    },
    loadOrganizationError(state) {
      state.loading = false;
    },
    updateOrganizationStart(state) {
      state.saving = true;
    },
    updateOrganizationSuccess(state, { payload }) {
      const { organization } = payload;

      state.organization = organization;
      state.saving = false;
    },
    updateOrganizationError(state) {
      state.saving = false;
    },
    loadJobFunctionsSuccess(state, { payload }) {
      const { jobFunctions } = payload;

      state.jobFunctions = jobFunctions;
    },
    createOrganizationTopicStart(state) {
      state.saving = true;
    },
    createOrganizationTopicSuccess(state, { payload }) {
      const { topic, organizationUpdatedAt } = payload;
      const topics = [...state.organization.topics, topic];

      state.organization = { ...state.organization, topics, organizationUpdatedAt };
      state.saving = false;
    },
    createOrganizationTopicError(state) {
      state.saving = false;
    },
    updateOrganizationTopicStart(state) {
      state.saving = true;
    },
    updateOrganizationTopicSuccess(state, { payload }) {
      const { topic, organizationUpdatedAt } = payload;
      const {
        organization: { topics },
      } = state;
      const updatedTopicIndex = findIndex(propEq(topic.id, 'id'), topics);
      const newTopics = update(updatedTopicIndex, topic, topics);

      state.organization = { ...state.organization, topics: newTopics, organizationUpdatedAt };
      state.saving = false;
    },
    updateOrganizationTopicError(state) {
      state.saving = false;
    },
    destroyOrganizationTopicSuccess(state, { payload }) {
      const { topicId, organizationUpdatedAt } = payload;
      const {
        organization: { topics },
      } = state;
      const newTopics = reject(propEq(topicId, 'id'), topics);

      state.organization = { ...state.organization, topics: newTopics, organizationUpdatedAt };
    },
    updateOrganizationLogoStart(state) {
      state.saving = true;
    },
    updateOrganizationLogoSuccess(state, { payload }) {
      const { logoUrl, logoUpdatedAt, organizationUpdatedAt, organizationInformationDigest } = payload;

      state.organization = {
        ...state.organization,
        logoUrl,
        logoUpdatedAt,
        organizationUpdatedAt,
        organizationInformationDigest,
      };
      state.saving = false;
    },
    updateOrganizationLogoError(state) {
      state.saving = false;
    },
    createOrganizationAliasStart(state) {
      state.saving = true;
    },
    createOrganizationAliasSuccess(state, { payload }) {
      const { alias, organizationUpdatedAt } = payload;
      const { organization } = state;
      const aliases = [...organization.aliases, alias];

      state.organization = { ...organization, aliases, organizationUpdatedAt };
      state.saving = false;
    },
    createOrganizationAliasError(state) {
      state.saving = false;
    },
    destroyOrganizationAliasStart(state) {
      state.saving = true;
    },
    destroyOrganizationAliasSuccess(state, { payload }) {
      const { aliasId, organizationUpdatedAt } = payload;
      const { organization } = state;
      const aliases = reject(propEq(aliasId, 'id'), organization.aliases);

      state.organization = { ...organization, aliases, organizationUpdatedAt };
      state.saving = false;
    },
    destroyOrganizationAliasError(state) {
      state.saving = false;
    },
  },
});

export const { actions } = adminOrganization;

export function loadOrganization(organizationId) {
  return (dispatch) => {
    dispatch(actions.loadOrganizationStart());

    return OrganizationsRepository.show(organizationId)
      .then(({ organization, meta }) => {
        dispatch(
          actions.loadOrganizationSuccess({
            organization: organization.draft,
            publishedOrganization: organization.published,
            ...meta,
          }),
        );

        dispatch(initializeRequestForm(meta.request));
      })
      .catch((error) => {
        dispatch(actions.loadOrganizationError());

        throw error;
      });
  };
}

export function updateOrganization(organizationId, params) {
  return (dispatch) => {
    dispatch(actions.updateOrganizationStart());

    return OrganizationDraftRepository.update(organizationId, params)
      .then(({ draft, meta: { request } }) => {
        dispatch(actions.updateOrganizationSuccess({ organization: draft }));

        dispatch(initializeRequestForm(request));

        return draft;
      })
      .catch((error) => {
        dispatch(actions.updateOrganizationError());

        throw error;
      });
  };
}

export function loadJobFunctions() {
  return (dispatch) =>
    JobFunctionsRepository.index().then(({ items }) =>
      dispatch(actions.loadJobFunctionsSuccess({ jobFunctions: items })),
    );
}

export function createOrganizationTopic(organizationId, params) {
  return (dispatch) => {
    dispatch(actions.createOrganizationTopicStart());

    return TopicsRepository.create(organizationId, params)
      .then(({ topic, meta }) => {
        dispatch(
          actions.createOrganizationTopicSuccess({
            topic,
            organizationUpdatedAt: meta.organizationUpdatedAt,
          }),
        );

        addNotification('Organization Topic was successfully created', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return topic;
      })
      .catch((error) => {
        dispatch(actions.createOrganizationTopicError());

        throw error;
      });
  };
}

export function updateOrganizationTopic(organizationId, topicId, params) {
  return (dispatch) => {
    dispatch(actions.updateOrganizationTopicStart());

    return TopicsRepository.update(organizationId, topicId, params)
      .then(({ topic, meta }) => {
        dispatch(
          actions.updateOrganizationTopicSuccess({
            topic,
            organizationUpdatedAt: meta.organizationUpdatedAt,
          }),
        );

        addNotification('Organization Topic was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return topic;
      })
      .catch((error) => {
        dispatch(actions.updateOrganizationTopicError());

        throw error;
      });
  };
}

export function destroyOrganizationTopic(organizationId, topicId) {
  return (dispatch) =>
    TopicsRepository.destroy(organizationId, topicId).then(({ meta }) => {
      dispatch(
        actions.destroyOrganizationTopicSuccess({
          topicId,
          organizationUpdatedAt: meta.organizationUpdatedAt,
        }),
      );

      addNotification('Organization Topic was successfully destroyed', 'normal')(dispatch);
      dispatch(initializeRequestForm(meta.request));
    });
}

export function updateLogo(organizationId, params) {
  return (dispatch) => {
    dispatch(actions.updateOrganizationLogoStart());

    return LogoRepository.update(organizationId, params)
      .then(({ logo, meta }) => {
        dispatch(
          actions.updateOrganizationLogoSuccess({
            logoUrl: logo.url,
            logoUpdatedAt: logo.updatedAt,
            organizationUpdatedAt: meta.organizationUpdatedAt,
            organizationInformationDigest: meta.organizationInformationDigest,
          }),
        );

        addNotification('Organization Logo was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));
        return logo;
      })
      .catch((error) => {
        dispatch(actions.updateOrganizationLogoError());

        throw error;
      });
  };
}

export function searchIndustry(filters) {
  const params = buildFilterParams(filters);

  return () => IndustriesRepository.index(params).then(({ items }) => items);
}

export function searchNaicsIndustryCode(filters) {
  const params = buildFilterParams(filters);

  return () => NaicsIndustryCodesRepository.index(params).then(({ items }) => items);
}

export function searchCountry(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return CountriesRepository.index(params).then(({ items }) => items);
  };
}

export function searchState(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return StatesRepository.index(params).then(({ items }) => items);
  };
}

export function searchTags(filters) {
  const query = { ...buildFilterParams(filters) };

  return () => TagRepository.nestedOptions(query).then(({ items }) => items);
}

export function createAlias(organizationId, params) {
  return (dispatch) => {
    dispatch(actions.createOrganizationAliasStart());

    return AliasesRepository.create(organizationId, params)
      .then(({ alias, meta }) => {
        dispatch(
          actions.createOrganizationAliasSuccess({
            alias,
            organizationUpdatedAt: meta.organizationUpdatedAt,
          }),
        );

        addNotification('Organization Alias was successfully created', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return alias;
      })
      .catch((error) => {
        dispatch(actions.createOrganizationAliasError());

        throw error;
      });
  };
}

export function deleteAlias(organizationId, aliasId) {
  return (dispatch) => {
    dispatch(actions.destroyOrganizationAliasStart());

    return AliasesRepository.destroy(organizationId, aliasId)
      .then(({ meta }) => {
        dispatch(
          actions.destroyOrganizationAliasSuccess({
            aliasId,
            organizationUpdatedAt: meta.organizationUpdatedAt,
          }),
        );

        addNotification('Organization Alias was successfully destroyed', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));
      })
      .catch((error) => {
        dispatch(actions.destroyOrganizationAliasError());

        return error;
      });
  };
}

export const useAdminOrganizationActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadOrganization,
      updateOrganization,
      loadJobFunctions,
      createOrganizationTopic,
      updateOrganizationTopic,
      destroyOrganizationTopic,
      updateLogo,
      searchIndustry,
      searchNaicsIndustryCode,
      searchCountry,
      searchState,
      searchTags,
      createAlias,
      deleteAlias,
    },
    dispatch,
  );
};

export default adminOrganization.reducer;
