/* eslint-disable no-param-reassign  */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { includes, findIndex, reject, update, prop, propEq } from 'ramda';
import { sortByOrderArray } from 'utils/SortHelper';

import ProfilesRepository from 'repositories/admin/ProfilesRepository';
import ProfileDraftRepository from 'repositories/admin/Profile/DraftRepository';
import JobFunctionsRepository from 'repositories/admin/JobFunctionsRepository';
import ProfileTopicRepository from 'repositories/admin/Profile/TopicRepository';
import PhotoRepository from 'repositories/admin/Profile/PhotoRepository';
import CountriesRepository from 'repositories/admin/CountriesRepository';
import StatesRepository from 'repositories/admin/StatesRepository';
import TagRepository from 'repositories/admin/TagRepository';
import LanguagesRepository from 'repositories/admin/LanguagesRepository';

import { buildFilterParams } from 'utils/FilterParamsBuilder';
import { addNotification } from 'containers/Notifications';
import { initializeRequestForm } from 'containers/AdminPanel/containers/RequestForm';

const updateInArray = (array, value, keyField = 'id') => {
  const keyValue = value[keyField];
  const index = findIndex(propEq(keyValue, keyField), array);

  return update(index, value, array);
};

const initialState = {
  loading: false,
  saving: false,
  publishedProfile: null,
  profile: null,
  jobFunctions: [],
  organizations: [],
  users: [],
  isThomsonReutersAvailable: false,
};

const adminProfile = createSlice({
  name: 'adminProfile',
  initialState,
  reducers: {
    updateProfile(state, { payload }) {
      state.profile = payload.profile;
    },
    /* eslint-disable no-unused-vars  */
    loadProfileStart(state) {
      state = { ...initialState, loading: true };
    },
    loadProfileSuccess(state, { payload }) {
      const { profile, publishedProfile, isThomsonReutersAvailable } = payload;

      state.profile = profile;
      state.publishedProfile = publishedProfile;
      state.isThomsonReutersAvailable = isThomsonReutersAvailable;
      state.loading = false;
    },
    updateProfileStart(state) {
      state.saving = true;
    },
    updateProfileSuccess(state, { payload }) {
      state.profile = payload.profile;
      state.saving = false;
    },
    updateProfileError(state) {
      state.saving = false;
    },
    loadJobFunctionsSuccess(state, { payload }) {
      state.jobFunctions = payload.jobFunctions;
    },
    updateProfileTopicStart(state) {
      state.saving = true;
    },
    updateProfileTopicSuccess(state, { payload }) {
      const { collection, topic, profileUpdatedAt, order } = payload;
      const {
        profile: { [collection]: topics },
      } = state;

      const newTopics = sortByOrderArray(updateInArray(topics, topic), order, 'sortUuid');

      state.saving = false;
      state.profile = { ...state.profile, [collection]: newTopics, profileUpdatedAt };
    },
    updateProfileTopicError(state) {
      state.saving = false;
    },
    createProfileTopicStart(state) {
      state.saving = true;
    },
    createProfileTopicSuccess(state, { payload }) {
      const { collection, topic, profileUpdatedAt, order } = payload;
      const {
        profile: { [collection]: topics },
      } = state;
      const newTopics = sortByOrderArray([...topics, topic], order, 'sortUuid');

      state.saving = false;
      state.profile = { ...state.profile, [collection]: newTopics, profileUpdatedAt };
    },
    createProfileTopicError(state) {
      state.saving = false;
    },
    destroyProfileTopicSuccess(state, { payload }) {
      const { collection, topicId, profileUpdatedAt } = payload;
      const {
        profile: { [collection]: topics },
      } = state;
      const newTopics = reject(propEq(topicId, 'id'), topics);

      state.saving = false;
      state.profile = { ...state.profile, [collection]: newTopics, profileUpdatedAt };
    },
    updateProfilePhotoStart(state) {
      state.saving = true;
    },
    updateProfilePhotoSuccess(state, { payload }) {
      state.saving = false;
      state.profile = {
        ...state.profile,
        photoUrl: payload.photoUrl,
        photoUpdatedAt: payload.photoUpdatedAt,
        profileUpdatedAt: payload.profileUpdatedAt,
        personalInformationDigest: payload.personalInformationDigest,
      };
    },
    updateProfilePhotoError(state) {
      state.saving = false;
    },
    updateProfileVisibilityStart(state) {
      state.saving = true;
    },
    updateProfileVisibilitySuccess(state, { payload }) {
      const { profile, publishedProfile } = payload;

      state.profile = profile;
      state.publishedProfile = publishedProfile;
      state.saving = false;
    },
    updateProfileVisibilityError(state) {
      state.saving = false;
    },
    createWorkStart(state) {
      state.saving = true;
    },
    createWorkSuccess(state, { payload }) {
      const { work, order, profileUpdatedAt } = payload;
      const {
        profile: { works },
      } = state;
      const newWorks = sortByOrderArray([...works, work], order, 'id');

      state.saving = false;
      state.profile = { ...state.profile, works: newWorks, profileUpdatedAt };
    },
    createWorkError(state) {
      state.saving = false;
    },
    updateWorkStart(state) {
      state.saving = true;
    },
    updateWorkSuccess(state, { payload }) {
      const {
        profile: { works, currentWorks },
      } = state;
      const { work, order, profileUpdatedAt, personalInformationDigest, draftVersion } = payload;
      const newWorks = sortByOrderArray(updateInArray(works, work), order, 'id');
      const currentWorkIds = currentWorks.map(prop('id'));

      state.saving = false;

      if (includes(work.id, currentWorkIds)) {
        state.profile = draftVersion;
        return;
      }

      state.profile = {
        ...state.profile,
        works: newWorks,
        profileUpdatedAt,
        personalInformationDigest,
      };
    },
    updateWorkError(state) {
      state.saving = false;
    },
    destroyWorkSuccess(state, { payload }) {
      const {
        profile: { works, currentWorks },
      } = state;
      const { workId, draftVersion } = payload;
      const currentWorkIds = currentWorks.map(prop('id'));
      const newWorks = reject(propEq(workId, 'id'), works);

      state.saving = false;

      if (includes(workId, currentWorkIds)) {
        state.profile = draftVersion;
        return;
      }

      state.profile = {
        ...state.profile,
        works: newWorks,
        profileUpdatedAt: payload.profileUpdatedAt,
      };
    },

    createInstitutionStart(state) {
      state.saving = true;
    },
    createInstitutionSuccess(state, { payload }) {
      const {
        profile: { institutions },
      } = state;
      const { institution, order, profileUpdatedAt } = payload;
      const newInstitutions = sortByOrderArray([...institutions, institution], order, 'id');

      state.saving = false;
      state.profile = { ...state.profile, institutions: newInstitutions, profileUpdatedAt };
    },
    createInstitutionError(state) {
      state.saving = false;
    },
    updateInstitutionStart(state) {
      state.saving = true;
    },
    updateInstitutionSuccess(state, { payload }) {
      const {
        profile: { institutions },
      } = state;
      const { institution, order, profileUpdatedAt } = payload;
      const newInstitutions = sortByOrderArray(updateInArray(institutions, institution), order, 'id');

      state.saving = false;
      state.profile = { ...state.profile, institutions: newInstitutions, profileUpdatedAt };
    },
    updateInstitutionError(state) {
      state.saving = false;
    },
    destroyInstitutionStart(state) {
      state.saving = true;
    },
    destroyInstitutionSuccess(state, { payload }) {
      const {
        profile: { institutions },
      } = state;
      const newInstitutions = reject(propEq(payload.institutionId, 'id'), institutions);

      state.saving = false;
      state.profile = {
        ...state.profile,
        institutions: newInstitutions,
        profileUpdatedAt: payload.profileUpdatedAt,
      };
    },

    createBoardStart(state) {
      state.saving = true;
    },
    createBoardSuccess(state, { payload }) {
      const {
        profile: { boards },
      } = state;
      const { board, order, profileUpdatedAt } = payload;
      const newBoards = sortByOrderArray([...boards, board], order, 'id');

      state.saving = false;
      state.profile = { ...state.profile, boards: newBoards, profileUpdatedAt };
    },
    createBoardError(state) {
      state.saving = false;
    },
    updateBoardStart(state) {
      state.saving = true;
    },
    updateBoardSuccess(state, { payload }) {
      const {
        profile: { boards },
      } = state;
      const { board, order, profileUpdatedAt } = payload;
      const newBoards = sortByOrderArray(updateInArray(boards, board), order, 'id');

      state.saving = false;
      state.profile = { ...state.profile, boards: newBoards, profileUpdatedAt };
    },
    updateBoardError(state) {
      state.saving = false;
    },
    destroyBoardSuccess(state, { payload }) {
      const {
        profile: { boards },
      } = state;
      const newBoards = reject(propEq(payload.boardId, 'id'), boards);

      state.saving = false;
      state.profile = {
        ...state.profile,
        boards: newBoards,
        profileUpdatedAt: payload.profileUpdatedAt,
      };
    },
    createProfileInterestStart(state) {
      state.saving = false;
    },
    createProfileInterestSuccess(state, { payload }) {
      const {
        profile: { profileInterests },
      } = state;

      state.saving = false;
      state.profile = {
        ...state.profile,
        profileInterests: [...profileInterests, payload.profileInterest],
        profileUpdatedAt: payload.profileUpdatedAt,
      };
    },
    createProfileInterestError(state) {
      state.saving = false;
    },
    destroyProfileInterestStart(state) {
      state.saving = true;
    },
    destroyProfileInterestSuccess(state, { payload }) {
      const {
        profile: { profileInterests },
      } = state;
      const newInterests = reject(propEq(payload.interestId, 'id'), profileInterests);

      state.saving = false;
      state.profile = {
        ...state.profile,
        profileInterests: newInterests,
        profileUpdatedAt: payload.profileUpdatedAt,
      };
    },
    addHighlightStart(state) {
      state.saving = true;
    },
    addHighlightSuccess(state, { payload }) {
      const { highlight } = payload;
      const {
        profile: { highlights },
      } = state;

      state.saving = false;
      state.profile = {
        ...state.profile,
        highlights: [...highlights, highlight],
      };
    },
    addHighlightError(state) {
      state.saving = false;
    },
    destroyHighlightStart(state) {
      state.saving = true;
    },
    destroyHighlightSuccess(state, { payload }) {
      const { highlightId } = payload;
      const {
        profile: { highlights },
      } = state;

      const filteredHighlights = highlights.filter(({ id }) => id !== highlightId);

      state.saving = false;
      state.profile = {
        ...state.profile,
        highlights: filteredHighlights,
      };
    },
    destroyHighlightError(state) {
      state.saving = false;
    },
    optOutStart(state) {
      state.saving = true;
    },
    optOutSuccess(state) {
      state.saving = false;
    },
  },
});

export const { actions } = adminProfile;

function loadProfile(id) {
  return (dispatch) => {
    dispatch(actions.loadProfileStart());

    return ProfilesRepository.show(id).then(({ profile: { draft, published }, meta }) => {
      dispatch(actions.loadProfileSuccess({ profile: draft, publishedProfile: published, ...meta }));

      dispatch(initializeRequestForm(meta.request));

      return draft;
    });
  };
}

function updateProfile(id, params, options = {}) {
  const { positiveUpdate } = options;

  return (dispatch) => {
    dispatch(actions.updateProfileStart());

    if (positiveUpdate) {
      dispatch(actions.updateProfile({ profile: params }));
    }

    return ProfileDraftRepository.update(id, params)
      .then(({ draft, meta: { request } }) => {
        dispatch(actions.updateProfileSuccess({ profile: draft }));

        dispatch(initializeRequestForm(request));

        return draft;
      })
      .catch((error) => {
        dispatch(actions.updateProfileError());

        throw error;
      });
  };
}

function loadJobFunctions() {
  return (dispatch) =>
    JobFunctionsRepository.index().then(({ items }) => {
      dispatch(actions.loadJobFunctionsSuccess({ jobFunctions: items }));
    });
}

function optOut(profileId) {
  return (dispatch) => {
    dispatch(actions.optOutStart());

    return ProfilesRepository.optOut(profileId).then(() => {
      dispatch(actions.optOutSuccess());

      loadProfile(profileId)(dispatch);
    });
  };
}

function updateProfileTopic(profileId, topicId, collection, order, params) {
  return (dispatch) => {
    dispatch(actions.updateProfileTopicStart());

    return ProfileTopicRepository.update(profileId, topicId, params)
      .then(({ topic, meta }) => {
        dispatch(
          actions.updateProfileTopicSuccess({
            topic,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta[order],
            request: meta.request,
            collection,
          }),
        );
        addNotification('Profile Topic was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return topic;
      })
      .catch((error) => {
        dispatch(actions.updateProfileTopicError());

        throw error;
      });
  };
}

function createProfileTopic(profileId, collection, order, params) {
  return (dispatch) => {
    dispatch(actions.createProfileTopicStart());

    return ProfileTopicRepository.create(profileId, params)
      .then(({ topic, meta }) => {
        dispatch(
          actions.createProfileTopicSuccess({
            topic,
            profileUpdatedAt: meta.profileUpdatedAt,
            order: meta[order],
            request: meta.request,
            collection,
          }),
        );

        addNotification('Profile Topic was successfully created', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return topic;
      })
      .catch((error) => {
        dispatch(actions.createProfileTopicError());

        throw error;
      });
  };
}

function destroyProfileTopic(profileId, topicId, collection) {
  return (dispatch) =>
    ProfileTopicRepository.destroy(profileId, topicId).then(({ meta }) => {
      dispatch(
        actions.destroyProfileTopicSuccess({
          topicId,
          profileUpdatedAt: meta.profileUpdatedAt,
          request: meta.request,
          collection,
        }),
      );

      dispatch(initializeRequestForm(meta.request));
    });
}

function updatePhoto(profileId, params) {
  return (dispatch) => {
    dispatch(actions.updateProfilePhotoStart());

    return PhotoRepository.update(profileId, params)
      .then(({ photo: { url, updatedAt }, meta }) => {
        dispatch(
          actions.updateProfilePhotoSuccess({
            photoUrl: url,
            photoUpdatedAt: updatedAt,
            profileUpdatedAt: meta.profileUpdatedAt,
            personalInformationDigest: meta.personalInformationDigest,
          }),
        );

        addNotification('Profile Photo was successfully updated', 'normal')(dispatch);
        dispatch(initializeRequestForm(meta.request));

        return url;
      })
      .catch((error) => {
        dispatch(actions.updateProfilePhotoError());

        throw error;
      });
  };
}

function updateProfileVisibility(profileId, params) {
  return (dispatch) => {
    dispatch(actions.updateProfileVisibilityStart());

    return ProfilesRepository.update(profileId, params)
      .then(({ profile: { draft, published } }) => {
        dispatch(actions.updateProfileVisibilitySuccess({ profile: draft, publishedProfile: published }));

        return draft;
      })
      .catch((error) => {
        dispatch(actions.updateProfileVisibilityError());

        throw error;
      });
  };
}

function searchCountry(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return CountriesRepository.index(params).then(({ items }) => items);
  };
}

function searchState(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return StatesRepository.index(params).then(({ items }) => items);
  };
}

function searchTags(filters) {
  const query = { ...buildFilterParams(filters) };

  return () => TagRepository.nestedOptions(query).then(({ items }) => items);
}

function searchLanguage(filters, options = {}) {
  const { page } = options;

  return () => {
    const params = { page, ...buildFilterParams(filters) };

    return LanguagesRepository.index(params).then(({ items }) => items);
  };
}

export const useAdminProfileActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      loadProfile,
      updateProfile,
      loadJobFunctions,
      optOut,
      updateProfileTopic,
      createProfileTopic,
      destroyProfileTopic,
      updatePhoto,
      updateProfileVisibility,
      searchCountry,
      searchState,
      searchTags,
      searchLanguage,
    },
    dispatch,
  );
};

export default adminProfile.reducer;
