import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { pluck, prop, without, any, not } from 'ramda';

import { buildProfileAutoSearchLinks } from 'utils/AutoSearch';
import { isPresent } from 'utils/HelperMethods';

import Routes from 'routes';

import Headline from 'components/grommet/Headline';
import Spinner from 'components/Spinner';
import Card from 'components/Card';
import Button from 'components/Button';

import PersonalInterests from './components/PersonalInterests';
import withConfirmation from 'hoc/withConfirmation';
import withReviewChanges from 'hoc/withReviewChanges';
import EditableCard from 'containers/AdminPanel/components/EditableCard';
import PersonalInfo from './components/PersonalInfo';
import TopicsList from './components/TopicsList';
import ContactInfo from './components/ContactInfo';
import WorksList from './components/WorksList';
import VersionStatusBlock from 'containers/AdminPanel/components/VersionStatusBlock';
import RequestForm from '../RequestForm';
import RequestBlock from '../../components/RequestBlock';
import BoardsList from './components/BoardsList';
import Institutions from './components/Institutions';
import OptedOut from './components/OptedOut';
import StyleGuides from 'containers/AdminPanel/components/StyleGuides';
import EditableContent from 'components/EditableContent';
import Languages from './components/Languages';
import Limbo from './components/Limbo';
import TREventsBlock from 'containers/AdminPanel/containers/TREventsBlock';
import HeadlineWithReviewChangesLink from 'containers/AdminPanel/components/HeadlineWithReviewChangesLink';
import OriginCountry from './components/OriginCountry';
import ReviewWorksList from './components/ReviewWorksList';
import ReviewBoardsList from './components/ReviewBoardsList';
import ReviewInstitutionsList from './components/ReviewInstitutionsList';
import ReviewTopicsList from './components/ReviewTopicsList';
import ReviewInterestsList from './components/ReviewInterestsList';
import ReviewLanguagesList from './components/ReviewLanguagesList';
import ReviewWysiwyg from 'containers/AdminPanel/components/ReviewWysiwyg';
import ReviewOriginCountry from './components/ReviewOriginCountry';
import ReviewPersonInformation from './components/ReviewPersonInformation';
import ReviewContactInformation from './components/ReviewContactInformation';
import AutoSearchLinks from 'containers/AdminPanel/components/AutoSearchLinks';
import Highlights from './components/Highlights';
import CurrentFortune500 from './components/CurrentFortune500';
import CurrentFtse100 from './components/CurrentFtse100';
import EditorNotes from 'containers/AdminPanel/components/EditorNotes';
import EditorWorks from 'containers/AdminPanel/components/EditorWorks';

import UserPresenter from 'presenters/UserPresenter';
import ProfilePresenter from 'presenters/ProfilePresenter';
import BoardPresenter from 'presenters/BoardPresenter';
import JobFunctionPresenter from 'presenters/JobFunctionPresenter';
import EditorWorkPresenter from 'presenters/EditorWorkPresenter';

import ProfilePolicy from 'policies/ProfilePolicy';
import TagPolicy from 'policies/TagPolicy';

import useAdminProfile from 'hooks/admin/useAdminProfile';
import useAdminRequestForm from 'hooks/admin/useAdminRequestForm';
import useAdminProfileDraftHighlight from 'hooks/admin/profile/useDraftHighlight';
import useAdminProfileEditorNotes from 'hooks/admin/profile/useEditorNotes';
import useAdminProfileEditorWorks from 'hooks/admin/profile/useEditorWorks';

import styles from './ProfilePage.module.css';

const ProfilePageComponent = (props) => {
  const {
    match,
    currentUser,
    isCollectionChanged,
    reviewChanges,
    isChanged,
    hasFirstlyAddedContent,
    loading,
    saving,
    publishedProfile,
    profile,
    jobFunctions,
    isThomsonReutersAvailable,
    loadProfile,
    updateProfile,
    loadJobFunctions,
    optOut,
    updateProfileTopic,
    createProfileTopic,
    destroyProfileTopic,
    updatePhoto,
    updateProfileVisibility,
    searchCountry,
    searchState,
    searchTags,
    searchLanguage,
  } = props;

  const { id: profileId } = match.params;
  const { addHighlight, destroyHighlight } = useAdminProfileDraftHighlight();
  const { request } = useAdminRequestForm();

  const {
    createEditorNote,
    updateEditorNote,
    destroyEditorNote,
    loadEditorNotes,
    filterEditorNotes,
    isSaving: isEditorNoteSaving,
    isLoading: isEditorNoteLoading,
    isDestroying: isEditorNoteDestroying,
    editorNotes,
    meta: editorNotesMeta,
  } = useAdminProfileEditorNotes();

  const {
    createEditorWork,
    updateEditorWork,
    destroyEditorWork,
    loadEditorWorks,
    filterEditorWorks,
    isSaving: isEditorWorkSaving,
    isLoading: isEditorWorkLoading,
    isDestroying: isEditorWorkDestroying,
    editorWorks,
    meta: editorWorksMeta,
  } = useAdminProfileEditorWorks();

  const [isEditorWorkModalOpen, setIsEditorWorkModalOpen] = useState(false);

  const handleEditorNotesFilter = (params) => filterEditorNotes(profileId, params);
  const handleEditorNotesLoad = (params) => loadEditorNotes(profileId, params);
  const handleEditorNoteCreate = (params) => createEditorNote(profileId, params);
  const handleEditorNoteUpdate = (editorNoteId, params) => updateEditorNote(profileId, editorNoteId, params);
  const handleEditorNoteDestroy = (editorNoteId) => destroyEditorNote(profileId, editorNoteId);

  const handleEditorWorksFilter = (params) => filterEditorWorks(profileId, params);
  const handleEditorWorksLoad = (params) => loadEditorWorks(profileId, params);
  const handleEditorWorkCreate = (params) => createEditorWork(profileId, params);
  const handleEditorWorkUpdate = (editorWorkId, params) => updateEditorWork(profileId, editorWorkId, params);
  const handleEditorWorkDestroy = (editorWorkId) => destroyEditorWork(profileId, editorWorkId);

  const canSeeTagBlocks = TagPolicy.canSeeTagBlocks(currentUser);
  const primaryCurrentWork = ProfilePresenter.primaryCurrentWork(profile);
  const canDragPinnedTopic = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);
  const canViewEditorNotes = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);
  const canViewEditorWorks = UserPresenter.isAdmin(currentUser) || UserPresenter.isEditor(currentUser);
  const hasEditorWorkToday = any(EditorWorkPresenter.isTodayCreated)(editorWorks);
  const needEditorWork = not(hasEditorWorkToday);
  const popover = useRef(null);

  const handleLoadProfile = () => {
    loadJobFunctions();
    loadProfile(profileId);
  };

  useEffect(() => {
    handleLoadProfile();
  }, []);

  const handleAddHighlight = ({ selectedText, tagId }) => {
    const newProfileId = ProfilePresenter.id(profile);
    return addHighlight({ profileId: newProfileId, params: { highlightedText: selectedText, tagId } });
  };

  const handleDestroyHighlight = (highlightId) => destroyHighlight(profileId, highlightId);

  const handleFieldUpdate =
    (fieldName, positiveUpdate = false) =>
    (value) => {
      const params = { ...profile, [fieldName]: value };
      return updateProfile(profile.id, params, { positiveUpdate });
    };

  const handleAssociationOrderUpdate = (association, fieldName, sortField) => (values) => {
    const params = { ...profile, [association]: values, [fieldName]: pluck(sortField, values) };

    return updateProfile(profile.id, params, { positiveUpdate: true });
  };

  const handleTopicVisibilityUpdate = (fieldName) => (uuid) => {
    const hiddenUuids = ProfilePresenter[fieldName](profile) || [];
    const newHiddenSortUuids = hiddenUuids.includes(uuid) ? without([uuid], hiddenUuids) : [...hiddenUuids, uuid];

    const params = { [fieldName]: newHiddenSortUuids };

    return updateProfile(profile.id, params);
  };

  const handlePhotoUpdate = (file, pixelCrop) => {
    const { id } = profile;
    const params = {
      photo: file,
      photoCropParams: pixelCrop,
    };

    return updatePhoto(id, params);
  };

  const handlePhotoDelete = () => {
    const { id } = profile;
    const params = { removePhoto: true };

    return updatePhoto(id, params);
  };

  const handleFieldsUpdate = (params) => updateProfile(profile.id, params);

  const handleProfileTopicUpdate = (collection, orderField) => (topicId, topic) =>
    updateProfileTopic(profile.id, topicId, collection, orderField, topic);

  const handleProfileTopicCreate = (collection, orderField) => (topic) =>
    createProfileTopic(profile.id, collection, orderField, topic);

  const handleProfileTopicDestroy = (collection) => (topicId) => destroyProfileTopic(profile.id, topicId, collection);

  const handleCountrySearch = (searchText) => {
    const filters = { nameCont: searchText, sortFields: [{ field: 'name', value: 'ASC' }] };

    return searchCountry(filters);
  };

  const handleStateSearch = (searchText) => {
    const filters = { nameCont: searchText, sortFields: [{ field: 'name', value: 'ASC' }] };

    return searchState(filters);
  };

  const handleLanguageSearch = (filters) => searchLanguage(filters);

  const handleOptOut = () => optOut(profile.id);

  const handleVisibilityChange = (params) => updateProfileVisibility(profile.id, params);

  const handleRequestCreate = () => updateProfile(profile.id, profile);

  const handleTagSearch = (searchText) => {
    const filters = { titleCont: searchText, ancestryNull: true };

    return searchTags(filters);
  };

  const handleLanguagesChange = (languages) => {
    const languageIds = languages.map(prop('id'));

    return handleFieldUpdate('languageIds')(languageIds);
  };

  const handleOriginCountryChange = (originCountry) => {
    const originCountryId = isPresent(originCountry) ? originCountry.id : null;

    return handleFieldUpdate('originCountryId')(originCountryId);
  };

  const isEditable = () => ProfilePolicy.canEdit(currentUser, profile, request);

  const handleReviewWysiwygChanges = (title, key) => reviewChanges(ReviewWysiwyg, title, key);

  const publishedPath = (newPublishedProfile) => Routes.profilePath(ProfilePresenter.slug(newPublishedProfile));

  const renderHeadline = (title, valueKey, reviewComponent) => (
    <HeadlineWithReviewChangesLink
      margin="none"
      strong
      size="small"
      title={title}
      isChanged={isCollectionChanged(valueKey)}
      onReviewChanges={reviewChanges(reviewComponent, title, valueKey)}
    />
  );

  const takeRelevantAndFortuneBoards = () => {
    const boards = ProfilePresenter.boards(profile);

    return boards.filter((board) => BoardPresenter.isRelevant(board) || BoardPresenter.isMemberFortune500(board));
  };

  const takeFtseBoards = () => {
    const boards = ProfilePresenter.boards(profile);

    return boards.filter((board) => BoardPresenter.isMemberFtse100(board));
  };

  if (loading || !profile || +profileId !== profile.id) {
    return <Spinner />;
  }

  if (ProfilePresenter.optedOut(profile)) {
    return <OptedOut profile={profile} />;
  }

  const editable = isEditable();
  const relevantAndFortuneBoards = takeRelevantAndFortuneBoards();
  const ftseBoards = takeFtseBoards();
  const isWarningPersonalAttributesAndInterests =
    (hasFirstlyAddedContent('publicPersonInformation') || isChanged('publicPersonInformation')) &&
    ProfilePresenter.isGdprProtected(profile);

  return (
    <>
      <div className={styles.root}>
        <Box pad="small" width="766px">
          <PersonalInfo
            editable={editable}
            profile={profile}
            jobFunctions={jobFunctions}
            onUpdate={handleFieldsUpdate}
            onPhotoUpdate={handlePhotoUpdate}
            onPhotoDelete={handlePhotoDelete}
            saving={saving}
            isChanged={isChanged('personalInformationDigest')}
            onReviewChanges={reviewChanges(ReviewPersonInformation, 'Personal Information', null, {
              jobFunctions,
            })}
          />
        </Box>
        <Box pad="small" width="medium">
          <ContactInfo
            editable={editable}
            profile={profile}
            onUpdate={handleFieldsUpdate}
            saving={saving}
            onCountrySearch={handleCountrySearch}
            publishedProfile={publishedProfile}
            onStateSearch={handleStateSearch}
            isChanged={isChanged('contactInformationDigest')}
            onReviewChanges={reviewChanges(ReviewContactInformation, 'Contact Information')}
            isCurrentOrganizationChanged={isChanged('currentOrganizationId')}
          />
        </Box>
      </div>

      <div className={styles.root}>
        <div ref={popover} />
        <Box pad="small" width="766px">
          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="Executive Summary"
              htmlBody={ProfilePresenter.briefBiography(profile)}
              onUpdate={handleFieldUpdate('briefBiography')}
              tooltipComponent={StyleGuides.ExecutiveSummaryStyleGuide}
              isChanged={isChanged('briefBiography')}
              onReviewChanges={handleReviewWysiwygChanges('Executive Summary', 'briefBiography')}
              enableHighlight={canSeeTagBlocks && editable}
              popoverNode={popover}
              handleAddHighlight={handleAddHighlight}
              handleLoadTags={handleTagSearch}
            />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="Personal Attributes and Interests"
              htmlBody={ProfilePresenter.publicPersonInformation(profile)}
              onUpdate={handleFieldUpdate('publicPersonInformation')}
              tooltipComponent={StyleGuides.PersonalAttributesAndInterestsStyleGuide}
              isChanged={isChanged('publicPersonInformation')}
              isWarning={isWarningPersonalAttributesAndInterests}
              onReviewChanges={handleReviewWysiwygChanges(
                'Personal Attributes and Interests',
                'publicPersonInformation',
              )}
              enableHighlight={canSeeTagBlocks && editable}
              popoverNode={popover}
              handleAddHighlight={handleAddHighlight}
              handleLoadTags={handleTagSearch}
              warningText="This executive’s address is in a gdpr-protected country. Please check that there is no protected personal data included. See style guide icon for details"
            />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <Card>
              {renderHeadline('Employment History', 'works', ReviewWorksList)}
              <WorksList
                profile={profile}
                editable={editable}
                saving={saving}
                primaryCurrentWork={primaryCurrentWork}
                works={ProfilePresenter.works(profile)}
                jobFunctions={jobFunctions}
              />
            </Card>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <Card>
              {renderHeadline('Other Board and Organizations', 'boards', ReviewBoardsList)}
              <BoardsList
                profile={profile}
                editable={editable}
                saving={saving}
                boards={ProfilePresenter.boards(profile)}
              />
            </Card>
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <Card>
              {renderHeadline('Education History', 'institutions', ReviewInstitutionsList)}
              <Institutions
                profile={profile}
                editable={editable}
                saving={saving}
                institutions={ProfilePresenter.institutions(profile)}
              />
            </Card>
          </Box>

          <Box margin={{ bottom: 'medium' }}>
            <Card>
              <TopicsList
                editable={editable}
                topicKind="current_focus"
                topics={ProfilePresenter.currentFocusTopics(profile)}
                jobFunctions={jobFunctions}
                formTitle="Current Focus"
                onSortEnd={handleAssociationOrderUpdate('currentFocusTopics', 'currentFocusUuidSortOrder', 'sortUuid')}
                onTopicUpdate={handleProfileTopicUpdate('currentFocusTopics', 'currentFocusUuidSortOrder')}
                onTopicCreate={handleProfileTopicCreate('currentFocusTopics', 'currentFocusUuidSortOrder')}
                onTopicDestroy={handleProfileTopicDestroy('currentFocusTopics')}
                saving={saving}
                tooltipComponent={StyleGuides.CurrentFocusStyleGuide}
                isChanged={isCollectionChanged('currentFocusTopics')}
                onReviewChanges={reviewChanges(ReviewTopicsList, 'Current Focus', 'currentFocusTopics')}
                onTagSearch={handleTagSearch}
                enableHighlight={canSeeTagBlocks && editable}
                popoverNode={popover}
                handleAddHighlight={handleAddHighlight}
                displayTags={canSeeTagBlocks}
                canDragPinnedTopic={canDragPinnedTopic}
                onTopicVisibilityUpdate={handleTopicVisibilityUpdate('currentFocusHiddenUuids')}
                hiddenUuids={ProfilePresenter.currentFocusHiddenUuids(profile)}
              />
            </Card>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <Card>
              <TopicsList
                editable={editable}
                topicKind="key_challenges"
                topics={ProfilePresenter.keyChallengesTopics(profile)}
                jobFunctions={jobFunctions}
                formTitle="Key Challenges"
                onSortEnd={handleAssociationOrderUpdate(
                  'keyChallengesTopics',
                  'keyChallengesUuidSortOrder',
                  'sortUuid',
                )}
                onTopicUpdate={handleProfileTopicUpdate('keyChallengesTopics', 'keyChallengesUuidSortOrder')}
                onTopicCreate={handleProfileTopicCreate('keyChallengesTopics', 'keyChallengesUuidSortOrder')}
                onTopicDestroy={handleProfileTopicDestroy('keyChallengesTopics')}
                saving={saving}
                tooltipComponent={StyleGuides.KeyChallengesStyleGuide}
                isChanged={isCollectionChanged('keyChallengesTopics')}
                onReviewChanges={reviewChanges(ReviewTopicsList, 'Key Challenges', 'keyChallengesTopics')}
                onTagSearch={handleTagSearch}
                enableHighlight={canSeeTagBlocks && editable}
                popoverNode={popover}
                handleAddHighlight={handleAddHighlight}
                displayTags={canSeeTagBlocks}
                canDragPinnedTopic={canDragPinnedTopic}
                onTopicVisibilityUpdate={handleTopicVisibilityUpdate('keyChallengesHiddenUuids')}
                hiddenUuids={ProfilePresenter.keyChallengesHiddenUuids(profile)}
              />
            </Card>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="News and Interviews"
              htmlBody={ProfilePresenter.newsLinks(profile)}
              onUpdate={handleFieldUpdate('newsLinks')}
              tooltipComponent={StyleGuides.NewsAndInterviewsStyleGuide}
              isChanged={isChanged('newsLinks')}
              onReviewChanges={handleReviewWysiwygChanges('News and Interviews', 'newsLinks')}
              enableHighlight={editable}
              popoverNode={popover}
              handleAddHighlight={handleAddHighlight}
              handleLoadTags={handleTagSearch}
            />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="Sources"
              htmlBody={ProfilePresenter.sources(profile)}
              onUpdate={handleFieldUpdate('sources')}
              tooltipComponent={StyleGuides.SourcesStyleGuide}
              isChanged={isChanged('sources')}
              onReviewChanges={handleReviewWysiwygChanges('Sources', 'sources')}
            />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableCard
              editable={editable}
              saving={saving}
              title="Legacy System Highlights"
              htmlBody={ProfilePresenter.biohighlights(profile)}
              onUpdate={handleFieldUpdate('biohighlights')}
              tooltipComponent={StyleGuides.BioSummaryStyleGuide}
              isChanged={isChanged('biohighlights')}
              onReviewChanges={handleReviewWysiwygChanges('Legacy System Highlights', 'biohighlights')}
            />
          </Box>
        </Box>
        <Box pad="small" width="medium">
          {relevantAndFortuneBoards.length > 0 && (
            <Box margin={{ bottom: 'medium' }}>
              <CurrentFortune500 boards={relevantAndFortuneBoards} />
            </Box>
          )}

          {ftseBoards.length > 0 && (
            <Box margin={{ bottom: 'medium' }}>
              <CurrentFtse100 boards={ftseBoards} />
            </Box>
          )}

          {canViewEditorNotes && (
            <Box margin={{ bottom: 'medium' }}>
              <EditorNotes
                onFilter={handleEditorNotesFilter}
                onLoad={handleEditorNotesLoad}
                onCreate={handleEditorNoteCreate}
                onUpdate={handleEditorNoteUpdate}
                onDestroy={handleEditorNoteDestroy}
                editorNotes={editorNotes}
                meta={editorNotesMeta}
                isSaving={isEditorNoteSaving}
                isLoading={isEditorNoteLoading}
                isDestroying={isEditorNoteDestroying}
              />
            </Box>
          )}

          {canViewEditorWorks && (
            <Box margin={{ bottom: 'medium' }}>
              <EditorWorks
                onFilter={handleEditorWorksFilter}
                onLoad={handleEditorWorksLoad}
                onCreate={handleEditorWorkCreate}
                onUpdate={handleEditorWorkUpdate}
                onDestroy={handleEditorWorkDestroy}
                editorWorks={editorWorks}
                meta={editorWorksMeta}
                isSaving={isEditorWorkSaving}
                isLoading={isEditorWorkLoading}
                isDestroying={isEditorWorkDestroying}
                kind="profile"
                isModalOpen={isEditorWorkModalOpen}
                onCloseModal={() => setIsEditorWorkModalOpen(false)}
              />
            </Box>
          )}

          <Box margin={{ bottom: 'medium' }}>
            <EditableContent tooltipComponent={StyleGuides.PersonalInterests}>
              <PersonalInterests
                editable={editable}
                onSortEnd={handleAssociationOrderUpdate('profileInterests', 'interestsUuidSortOrder', 'sortUuid')}
                interests={ProfilePresenter.profileInterests(profile)}
                saving={saving}
                isChanged={isCollectionChanged('profileInterests', 'interestId')}
                onReviewChanges={reviewChanges(ReviewInterestsList, 'Personal Interests', 'profileInterests')}
                profile={profile}
              />
            </EditableContent>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableContent tooltipComponent={StyleGuides.Languages}>
              <Languages
                editable={editable}
                languages={ProfilePresenter.languages(profile)}
                isChanged={isCollectionChanged('languages', 'id')}
                onLanguagesChange={handleLanguagesChange}
                onReviewChanges={reviewChanges(ReviewLanguagesList, 'Languages', 'languages')}
                saving={saving}
                onLanguagesSearch={handleLanguageSearch}
              />
            </EditableContent>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <EditableContent tooltipComponent={StyleGuides.OriginCountry}>
              <OriginCountry
                editable={editable}
                originCountry={ProfilePresenter.originCountry(profile)}
                isChanged={isChanged('originCountryId')}
                onOriginCountryChange={handleOriginCountryChange}
                onReviewChanges={reviewChanges(ReviewOriginCountry, 'Origin Country', 'originCountry')}
                saving={saving}
                onCountrySearch={handleCountrySearch}
              />
            </EditableContent>
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <AutoSearchLinks links={buildProfileAutoSearchLinks(profile, jobFunctions)} />
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <VersionStatusBlock
              object={publishedProfile}
              presenter={ProfilePresenter}
              publishedVersionPath={publishedPath}
              request={request}
              onRequestCreate={handleRequestCreate}
              saving={saving}
              canCreateRequest={editable}
            />
          </Box>

          {request && (
            <Box margin={{ bottom: 'medium' }}>
              <RequestForm
                currentUser={currentUser}
                request={request}
                onDeliver={handleLoadProfile}
                onCanceled={isPresent(publishedProfile) && handleLoadProfile}
                onOpenEditorWorkModal={() => setIsEditorWorkModalOpen(true)}
                needEditorWork={needEditorWork}
                view={RequestBlock}
              />
            </Box>
          )}

          {ProfilePolicy.canOptOut(currentUser) && (
            <Box margin={{ bottom: 'medium' }}>
              <Card>
                <Headline strong size="small" margin="none" className={styles.header}>
                  When Subject Opts Out
                </Headline>

                <Button label="Opt-Out" primary onClick={handleOptOut} />
              </Card>
            </Box>
          )}

          {ProfilePolicy.canVisibilityChange(currentUser) && (
            <Limbo
              saving={saving}
              state={ProfilePresenter.visibilityStatus(profile)}
              onVisibilityChange={handleVisibilityChange}
            />
          )}
          {isThomsonReutersAvailable && ProfilePresenter.currentOrganizationId(profile) && (
            <TREventsBlock organizationId={ProfilePresenter.currentOrganizationId(profile)} />
          )}

          {canSeeTagBlocks && (
            <Highlights
              editable={editable}
              highlights={ProfilePresenter.highlights(profile)}
              profile={profile}
              saving={saving}
              onDestroyHighlight={handleDestroyHighlight}
            />
          )}
        </Box>
      </div>
    </>
  );
};

const ProfilePageWithConfirmation = withConfirmation(ProfilePageComponent, {
  destroyProfileTopic: 'Are you sure want to delete topic?',
  optOut: 'Are you sure want to Opt-Out a profile?',
});

const ProfilePage = withReviewChanges(ProfilePageWithConfirmation, {
  currentValueKey: 'profile',
  previousValueKey: 'publishedProfile',
  getter: ProfilePresenter,
  useAdminSubject: useAdminProfile,
});

ProfilePageComponent.propTypes = {
  currentUser: UserPresenter.shape(),
  reviewChanges: PropTypes.func.isRequired,
  isCollectionChanged: PropTypes.func.isRequired,
  isChanged: PropTypes.func.isRequired,
  hasFirstlyAddedContent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  publishedProfile: ProfilePresenter.shape(),
  profile: ProfilePresenter.shape(),
  jobFunctions: PropTypes.arrayOf(JobFunctionPresenter.shape()).isRequired,
  isThomsonReutersAvailable: PropTypes.bool.isRequired,
  loadProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  loadJobFunctions: PropTypes.func.isRequired,
  optOut: PropTypes.func.isRequired,
  updateProfileTopic: PropTypes.func.isRequired,
  createProfileTopic: PropTypes.func.isRequired,
  destroyProfileTopic: PropTypes.func.isRequired,
  updatePhoto: PropTypes.func.isRequired,
  updateProfileVisibility: PropTypes.func.isRequired,
  searchCountry: PropTypes.func.isRequired,
  searchState: PropTypes.func.isRequired,
  searchTags: PropTypes.func.isRequired,
  searchLanguage: PropTypes.func.isRequired,
};

export default ProfilePage;
