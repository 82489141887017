import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isBlank, reactSelectDebounce } from 'utils/HelperMethods';

import TagAsyncSelect from 'components/TagAsyncSelect';
import Button from 'components/Button';
import Tags from 'containers/AdminPanel/components/Tags';

import TagPresenter from 'presenters/TagPresenter.js';

import styles from './TopicTagsSelect.module.css';

class TopicTagsSelect extends Component {
  static propTypes = {
    onTagAdd: PropTypes.func.isRequired,
    onTagDelete: PropTypes.func.isRequired,
    onTagSearch: PropTypes.func.isRequired,
    saving: PropTypes.bool.isRequired,
    tags: PropTypes.arrayOf(TagPresenter.shape()),
  };

  state = { currentTag: null };

  handleTagAdd = () => {
    const { onTagAdd } = this.props;
    const { currentTag } = this.state;

    onTagAdd(currentTag);
    this.setState({ currentTag: null });
  };

  handleTagSelect = (currentTag) => this.setState({ currentTag });

  handleTagSearch = reactSelectDebounce(this.props.onTagSearch);

  render() {
    const { onTagDelete, saving, tags } = this.props;
    const { currentTag } = this.state;

    return (
      <div className={styles.root}>
        <div className={styles.control}>
          <div className={styles.select}>
            <TagAsyncSelect
              disabled={saving}
              placeholder="Add a new tag"
              loadOptions={this.handleTagSearch}
              defaultOptions
              value={currentTag}
              onChange={this.handleTagSelect}
              getOptionValue={TagPresenter.id}
              getOptionLabel={TagPresenter.fullTitle}
              noBottom
              menuPlacement="top"
            />
          </div>

          <Button
            label="Add Tag"
            className={styles.button}
            onClick={this.handleTagAdd}
            disabled={isBlank(currentTag) || saving}
          />
        </div>
        <Tags items={tags} onDelete={onTagDelete} saving={saving} deletable />
      </div>
    );
  }
}

export default TopicTagsSelect;
